@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn); } }

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn); } }

.Select {
  position: relative; }
  .Select,
  .Select div,
  .Select input,
  .Select span {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .Select.is-disabled > .Select-control {
    background-color: #f9f9f9; }
    .Select.is-disabled > .Select-control:hover {
      box-shadow: none; }
  .Select.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none; }

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .Select-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }
  .Select-control .Select-input:focus {
    outline: none; }

.is-searchable.is-open > .Select-control {
  cursor: text; }

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9; }
  .is-open > .Select-control .Select-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px; }

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text; }

.is-focused:not(.is-open) > .Select-control {
  border-color: #08c #0099e6 #0099e6;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px fade(#08c, 50%); }

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333; }

.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none; }
  .has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover, .has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
    color: #08c;
    outline: none;
    text-decoration: underline; }

.Select-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle; }
  .Select-input > input {
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 14px;
    /* For IE 8 compatibility */
    padding: 8px 0 12px;
    /* For IE 8 compatibility */
    -webkit-appearance: none; }
    .is-focused .Select-input > input {
      cursor: text; }

.has-value.is-pseudo-focused .Select-input {
  opacity: 0; }

.Select-control:not(.is-searchable) > .Select-input {
  outline: none; }

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 16px; }

.Select-loading {
  -webkit-animation: Select-animation-spin 400ms infinite linear;
  -o-animation: Select-animation-spin 400ms infinite linear;
  animation: Select-animation-spin 400ms infinite linear;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
  -o-animation: Select-animation-fadeIn 200ms;
  animation: Select-animation-fadeIn 200ms;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px; }
  .Select-clear-zone:hover {
    color: #d0021b; }

.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1; }

.Select--multi .Select-clear-zone {
  width: 17px; }

.Select--multi .Select-multi-value-wrapper {
  display: inline-block; }

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden; }

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px; }

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative; }

.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666; }

@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch; }

.Select-menu {
  max-height: 198px;
  overflow-y: auto; }

.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666666;
  cursor: pointer;
  display: block;
  padding: 8px 10px; }
  .Select-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .Select-option.is-selected {
    background-color: #f5faff;
    color: #333; }
  .Select-option.is-focused {
    background-color: #f2f9fc;
    color: #333; }
  .Select-option.is-disabled {
    color: #cccccc;
    cursor: default; }

.Select-noresults {
  box-sizing: border-box;
  color: #999999;
  cursor: default;
  display: block;
  padding: 8px 10px; }

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0; }

.Select--multi.has-value .Select-input {
  margin-left: 5px; }

.Select--multi .Select-value {
  background-color: #f2f9fc;
  border-radius: 2px;
  border: 1px solid #c9e6f2;
  color: #08c;
  display: inline-block;
  font-size: 0.9em;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top; }

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle; }

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px; }

.Select--multi a.Select-value-label {
  color: #08c;
  cursor: pointer;
  text-decoration: none; }
  .Select--multi a.Select-value-label:hover {
    text-decoration: underline; }

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c9e6f2;
  padding: 1px 5px 3px; }
  .Select--multi .Select-value-icon:hover, .Select--multi .Select-value-icon:focus {
    background-color: #ddeff7;
    color: #0077b3; }
  .Select--multi .Select-value-icon:active {
    background-color: #c9e6f2; }

.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333; }

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3; }
  .Select--multi.is-disabled .Select-value-icon:hover, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:active {
    background-color: #fcfcfc; }

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn); } }

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn); } }

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn); } }

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn); } }

.Select {
  position: relative; }
  .Select,
  .Select div,
  .Select input,
  .Select span {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .Select.is-disabled > .Select-control {
    background-color: #f9f9f9; }
    .Select.is-disabled > .Select-control:hover {
      box-shadow: none; }
  .Select.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none; }

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .Select-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }
  .Select-control .Select-input:focus {
    outline: none; }

.is-searchable.is-open > .Select-control {
  cursor: text; }

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9; }
  .is-open > .Select-control .Select-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px; }

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text; }

.is-focused:not(.is-open) > .Select-control {
  border-color: #08c #0099e6 #0099e6;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px fade(#08c, 50%); }

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333; }

.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none; }
  .has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover, .has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
    color: #08c;
    outline: none;
    text-decoration: underline; }

.Select-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle; }
  .Select-input > input {
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 14px;
    /* For IE 8 compatibility */
    padding: 8px 0 12px;
    /* For IE 8 compatibility */
    -webkit-appearance: none; }
    .is-focused .Select-input > input {
      cursor: text; }

.has-value.is-pseudo-focused .Select-input {
  opacity: 0; }

.Select-control:not(.is-searchable) > .Select-input {
  outline: none; }

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 16px; }

.Select-loading {
  -webkit-animation: Select-animation-spin 400ms infinite linear;
  -o-animation: Select-animation-spin 400ms infinite linear;
  animation: Select-animation-spin 400ms infinite linear;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
  -o-animation: Select-animation-fadeIn 200ms;
  animation: Select-animation-fadeIn 200ms;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px; }
  .Select-clear-zone:hover {
    color: #d0021b; }

.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1; }

.Select--multi .Select-clear-zone {
  width: 17px; }

.Select--multi .Select-multi-value-wrapper {
  display: inline-block; }

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden; }

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px; }

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative; }

.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666; }

@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch; }

.Select-menu {
  max-height: 198px;
  overflow-y: auto; }

.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666666;
  cursor: pointer;
  display: block;
  padding: 8px 10px; }
  .Select-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px; }
  .Select-option.is-selected {
    background-color: #f5faff;
    color: #333; }
  .Select-option.is-focused {
    background-color: #f2f9fc;
    color: #333; }
  .Select-option.is-disabled {
    color: #cccccc;
    cursor: default; }

.Select-noresults {
  box-sizing: border-box;
  color: #999999;
  cursor: default;
  display: block;
  padding: 8px 10px; }

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0; }

.Select--multi.has-value .Select-input {
  margin-left: 5px; }

.Select--multi .Select-value {
  background-color: #f2f9fc;
  border-radius: 2px;
  border: 1px solid #c9e6f2;
  color: #08c;
  display: inline-block;
  font-size: 0.9em;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top; }

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle; }

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px; }

.Select--multi a.Select-value-label {
  color: #08c;
  cursor: pointer;
  text-decoration: none; }
  .Select--multi a.Select-value-label:hover {
    text-decoration: underline; }

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c9e6f2;
  padding: 1px 5px 3px; }
  .Select--multi .Select-value-icon:hover, .Select--multi .Select-value-icon:focus {
    background-color: #ddeff7;
    color: #0077b3; }
  .Select--multi .Select-value-icon:active {
    background-color: #c9e6f2; }

.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333; }

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3; }
  .Select--multi.is-disabled .Select-value-icon:hover, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:active {
    background-color: #fcfcfc; }

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn); } }

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn); } }

.rc-time-picker {
  display: inline-block;
  box-sizing: border-box; }

.rc-time-picker * {
  box-sizing: border-box; }

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); }

.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box; }

.rc-time-picker-panel * {
  box-sizing: border-box; }

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5; }

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent; }

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9; }

.rc-time-picker-panel-input-invalid {
  border-color: red; }

.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0; }

.rc-time-picker-panel-clear-btn:after {
  content: "x";
  font-size: 12px;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease; }

.rc-time-picker-panel-clear-btn:hover:after {
  color: #666; }

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative; }

.rc-time-picker-panel-select-active {
  overflow-y: auto; }

.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0; }

.rc-time-picker-panel-select:last-child {
  border-right: 0; }

.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 144px; }

.rc-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rc-time-picker-panel-select li:hover {
  background: #edfaff; }

li.rc-time-picker-panel-select-option-selected {
  background: #edfaff;
  color: #2db7f5; }

li.rc-time-picker-panel-select-option-disabled {
  color: #bfbfbf; }

li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed; }
